<template>
  <div>
    <v-app-bar
      fixed
      app
      dark
      :color="bgColor"
    >
      <v-row>
        <v-col
          md="3"
          class="d-flex justify-start align-center"
        >
          <v-btn
            text
            class="pa-0"
            @click="
          $router.push({
            name: 'mainPage',
          }).catch(o => {})
        "
          >
            <v-icon
              class="mx-3"
              color="green"
            >mdi-cloud</v-icon>

            <v-toolbar-title class="mr-4 align-center">
              <span
                class="title"
                style="text-transform: initial;"
              >
                {{title }}
              </span>
            </v-toolbar-title>
          </v-btn>

        </v-col>
        <v-col md="6"></v-col>
        <v-col
          v-if="user"
          md="3"
          class="d-flex justify-end"
        >
          <v-btn
            style="width: 48px;height: 48px;"
            color="green"
            dark
            class="mr-6"
            fab
            @click="$store.commit('events/addCards', true)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                class="mr-md-2 mr-0 mr-sm-0"
              >
                <v-avatar
                  color="blue"
                  :title="user.name"
                >
                  <img
                    v-if="user.image"
                    :src="user.image"
                  />
                  <span v-else>
                    <span v-if="user.name">{{ user.name.slice(0, 4) }}</span>
                    <span v-else>{{ user.email.slice(0, 4) }}</span>
                  </span>
                </v-avatar>
              </v-btn>
            </template>

            <v-list
              class="top-menu-item"
              dense
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :title="user.email">{{
                    user.email
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item-group :color="mainColor">
                <v-list-item @click.prevent="$refs.profile.drawer = !$refs.profile.drawer">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-face-profile</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("header.profile")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                  @click.prevent="exit"
                  color="red"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon color="red lighten-2">mdi-location-exit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("header.exit")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
        <v-col
          v-else
          md="3"
          class="d-flex justify-end"
        >
          <v-btn
            color="green"
            @click="$router.push({ query: { action: 'login' } })"
            dark
            class="mr-6"
          >
            <v-icon left>mdi-login</v-icon>Вход
          </v-btn>

          <v-btn
            color="blue"
            @click="$router.push({ query: { action: 'register' } })"
            dark
          >
            <v-icon left>mdi-lock</v-icon>Регистрация
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <!-- <login ref="login" />
    <register ref="register" /> -->
    <panelAddCards />
  </div>
</template>

<script>
// import login from "~/components/auth/login";
// import register from "~/components/auth/register";
import panelAddCards from "@/modules/widgets/components/panel/panel.vue";
export default {
  components: {
    // login,
    // register,
    panelAddCards,
  },
  data() {
    return {
      title: "Start",
    };
  },
  mounted() {
    // this.startModals();
  },
  watch: {
    $route() {
      //  this.startModals();
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    exit() {
      this.$store.commit("auth/logout");
    },
    startModals() {
      if (this.user) return;
      if (this.$route.query.action === "login") {
        this.$refs.login.dialog = true;
      } else {
        this.$refs.login.dialog = false;
      }

      if (this.$route.query.action === "register") {
        this.$refs.register.dialog = true;
      } else {
        this.$refs.register.dialog = false;
      }
    },
  },
};
</script>

<style lang="scss">
.main-enter {
  overflow: visible !important;
  .text-start {
    max-width: 420px;
    margin: 0 auto;
  }
}
</style>
