const state = {
    loading: false,
    items: []
}



export default {
    namespaced: true,
    state
}