<template>
  <v-navigation-drawer
    fixed
    temporary
    right
    width="380"
    v-model="active"
    :clipped="$vuetify.breakpoint.lgAndUp"
    style="background-color: #F6F8F6;"
    app
  >
    <div
      class="px-4 py-4"
      v-if="step === 0"
    >
      <div class="title mb-8">Что вы хотите добавить?</div>
      <v-card
        class="mx-auto"
        tile
      >
        <v-list dense>
          <div
            v-for="(item, index) in list"
            :key="index"
          >
            <v-list-item @click="$emit('addCard', item)">
              <v-list-item-icon
                v-if="item.icon"
                class="mr-2"
              >
                <v-icon
                  small
                  :color="item.color"
                  v-text="item.icon"
                ></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
          <v-list-item @click="step = 1">
            <v-list-item-content>
              <v-list-item-title>Найти другие виджеты</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <div
      class="px-4 py-4"
      v-if="step === 1"
    >
      <div
        class="title mb-8"
        @click="step = 0"
        style="cursor: pointer;"
      >
        <v-icon class="mr-2">mdi-chevron-left</v-icon> Другие виджеты
      </div>
      <v-card
        class="mx-auto"
        tile
      >
        <v-list dense>
          <div
            v-for="(item, index) in listOther"
            :key="index"
          >
            <v-list-item @click="$emit('addCard', item)">
              <v-list-item-icon
                v-if="item.icon"
                class="mr-2"
              >
                <v-icon
                  small
                  :color="item.color"
                  v-text="item.icon"
                ></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="(listOther.length - 1) !== index"></v-divider>
          </div>
        </v-list>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      step: 0,
    };
  },
  created() {
    this.$bus.on("addPanel", this.showPanel);
  },
  beforeDestroy() {
    this.$bus.off("addPanel", this.showPanel);
  },
  methods: {
    showPanel(data) {
      // console.log(data);
      this.active = true;
    },
  },
  computed: {
    list() {
      return this.$store.state.widgets.items.filter((o) => o.group === "main");
    },
    listOther() {
      return this.$store.state.widgets.items.filter((o) => o.group !== "main");
    },
  },
};
</script>