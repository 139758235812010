import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import mixinGlobal from './mixins/global'
// Глобальный миксин
Vue.mixin(mixinGlobal)

import VueBus from 'vue-bus';
Vue.use(VueBus);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
