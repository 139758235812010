//import axios from 'axios'


const state = {
    loading: false,
    apiUrl: 'api/',
    route: 'auth',
    refresh_token: null,
    token: null,
    user: null,
    time: null,

}



export default {
    namespaced: true,
    state
}