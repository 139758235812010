import rootStore from '../index'
//import axios from 'axios';

const state = {
    list: [],
    error: null
}


// function setToTelegram(data: any) {

//     let token = process.env.VUE_APP_TELEGRAM_TOKEN;
//     if (!token) return;

//     let telegramData = {
//         status: data.response.status,
//         url: data.response.config.url,
//         link: data.response.config.url,
//         method: data.response.config.method,
//         date: data.date,
//         message: data.message,
//         params: data.response.config.params,
//         headers: data.response.config.headers,
//         data: data.response.data,
//     };
//     //console.log(rootStore);
//     //return;

//     let route = `https://api.telegram.org/bot${token}/sendMessage`;

//     let text = `*Ошибка* 
//   user: ${rootStore.state.users.profile.email}
//   link: ${location.href}
//   url: ${telegramData.url}
//   method: ${telegramData.method}
//   status: ${telegramData.status}
//   headers:  
//   \`${JSON.stringify(telegramData.headers, null, 2)}\`
//   params: 
//   \`${JSON.stringify(telegramData.params, null, 2)}\`
//   data: 
//   \`${JSON.stringify(telegramData.data, null, 2)}\`
//    `;
//     //  console.log(JSON.stringify(telegramData.data, null, 2));
//     let message = {
//         chat_id: +process.env.VUE_APP_TELEGRAM_CHAT_ID,
//         text,
//         parse_mode: "Markdown",
//         disable_web_page_preview: "True"
//     };
//     return axios.post(route, message).then(response => {
//         // console.log(response);
//         return response.data
//     }).catch(error => {
//         return error
//     })
// };

const mutations = {
    async setItem(state: any, item: any) {

        if (item.response) {
            if (item.response.status === 404) return;
            if (item.response.status === 403) {
                state.error = item.response ?
                    item.response.data.message :
                    item.message;
                return;
            }
            if (item.response.status === 401) {
                const resp = await rootStore.dispatch('auth/updateToken');
                if (!resp.message) {
                    //  location.reload();
                }
                // return;
            }
        }


        if (item.response && item.response.status < 423) return;

        if (item.message && !item.response) {
            // let t = item.request.onreadystatechange();
            //console.log(item.config);
            item.response = {
                config: item.config
            };
        }

        const data = {
            date: Date.now(),
            message: item.message,
            response: item.response
        };
        // console.log(data, item);

        if (data.response && data.response.config) {
            //  setToTelegram(data);
        }

        state.list.push(data);
    },
    clear(state: any) {
        state.list = [];
    }
}

const actions = {

    // debug(store, item) {
    //     //  console.log(item);
    //     let data = [
    //         item.config.url, item.config.data, {
    //             headers: item.config.headers,
    //             params: {
    //                 ...item.config.params,
    //                 query: 1
    //             },
    //         }
    //     ];

    //     if (item.config.method === 'get') {
    //         data = [
    //             item.config.url, {
    //                 headers: item.config.headers,
    //                 params: {
    //                     ...item.config.params,
    //                     query: 1
    //                 },
    //             }
    //         ];
    //     }
    //     return axios[item.config.method](...data).then(response => {
    //         return response
    //     }).catch(error => {
    //         console.log(error.response)
    //         return error.response
    //     })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}