<template>
  <v-card class="start-card">
    <div class="d-flex justify-space-between align-center pa-2 header-card vue-draggable-handle">
      <div class="font-weight-medium pl-1 ">{{ data.title }}</div>
      <div class="btns d-flex">
        <div class="btns-hides mr-3">
          <!-- <v-btn
            small
            icon
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn> -->
          <v-btn
            small
            icon
            @click="addPanel"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

        <v-menu
          bottom
          left
          v-if="dotsNav.length"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in dotsNav"
              :key="index"
              @click="clickNav({event:item.event, item, data})"
            >
              <v-list-item-icon class="mr-2">
                <v-icon
                  small
                  :color="item.color"
                  v-text="item.icon"
                ></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="btns-drag">
        <v-icon>mdi-arrow-all</v-icon>
      </div>
    </div>
    <div class="px-3 pb-4 start-card-content wr-sc">
      <!-- <div
        v-if="data.type_id == 'embed'"
        v-html="data.data.content"
      ></div> -->
      <component
        :is="widget"
        v-if="data.component"
        ref="widget"
      />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {},
  mounted() {
    setTimeout(() => {
      if (this.$refs.widget && this.$refs.widget.dotsNav) {
        this.add = this.$refs.widget.dotsNav;
      }
    }, 300);
  },
  computed: {
    widget() {
      return () => import(`./widgets/${this.data.component}.vue`);
    },
    dotsNav() {
      let list = [];
      if (this.data.is_edit) {
        list.push({
          title: "Редактировать",
          icon: "mdi-pencil",
          event: "editCard",
        });
      }
      if (this.data.is_copy) {
        list.push({
          title: "Копировать",
          icon: "mdi-content-copy",
          event: "copy",
        });
      }

      if (this.add.length) {
        list = [...list, ...this.add];
      }

      if (this.data.is_deleted) {
        list.push({
          title: "Удалить",
          icon: "mdi-delete",
          color: "red",
          event: "removeCard",
        });
      }

      return list;
    },
  },
  data() {
    return {
      add: [],
      // dotsNav: [
      //   // { title: "Добавить закладку", icon: "mdi-plus" },
      //   // { title: "Добавить группу", icon: "mdi-folder" },
      //   { title: "Редактировать", icon: "mdi-pencil", event: "editCard" },
      //   { title: "Копировать", icon: "mdi-content-copy", event: "copy" },
      //   // { title: "Настройки", icon: "mdi-cogs" },
      //   {
      //     title: "Удалить",
      //     icon: "mdi-delete",
      //     color: "red",
      //     event: "removeCard",
      //   },
      // ],
    };
  },
  methods: {
    addPanel() {
      this.$bus.emit("addPanel", this.data);
    },
    clickNav(data) {
      // console.log(data);
      if (data.event) {
        this.$emit("nav", data);
      }
      if (data.item.func) {
        data.item.func();
      }
    },
  },
};
</script>


<style lang="scss">
.start-card-content {
  height: calc(100% - 50px);
}
</style>