
import Home from './pages/main.vue'
export default [

    {
        path: '/',
        name: 'mainPage',
        // component: () => import('./pages/main.vue'),
        component: Home
    }
]