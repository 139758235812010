import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
//import ru from 'vuetify/src/locale/ru.ts'

Vue.use(Vuetify);

const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    lang: {
        // locales: {
        //     ru
        // },
        current: 'ru',
    },
})

Vue.use(VuetifyDialog, {
    context: {
        vuetify
    },
    confirm: {
        actions: {
            false: 'Отмена',
            true: {
                text: 'Да',
                color: 'primary'
            }
        }
    }
})

export default vuetify