



















import Vue from "vue";
import mainHeader from "./components/header.vue";

export default Vue.extend({
  name: "App",

  components: {
    mainHeader,
  },

  data: () => ({
    //
  }),
});
