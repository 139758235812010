<template>
  <div class="wrap-grid">
    <grid-layout
      :layout.sync="layout"
      :col-num="12"
      :row-height="30"
      :is-draggable="true"
      :is-resizable="true"
      :vertical-compact="true"
      :use-css-transforms="true"
    >
      <grid-item
        v-for="(item, i) in layout"
        :key="i"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :min-w="2"
        :max-w="6"
        :min-h="6"
        :max-h="12"
        drag-allow-from=".vue-draggable-handle"
        drag-ignore-from=".no-drag"
      >
        <startCard
          @nav="eventCard"
          :data="item"
        />
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import startCard from "../components/startCard";
export default {
  components: {
    startCard,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  data() {
    return {
      layout: [
        {
          _id: 1,
          x: 0,
          y: 0,
          w: 5,
          h: 6,
          title: "news",
          i: "0",
          type_id: 1,
          component: "articles",
          is_deleted: false,
          is_copy: true,
          is_edit: false,
        },
        {
          _id: 2,
          x: 5,
          y: 0,
          w: 4,
          h: 7,
          title: "links",
          i: "1",
          type_id: 2,
          component: "links",
          is_deleted: false,
          is_copy: false,
          is_edit: false,
        },
        {
          _id: 3,
          x: 6,
          y: 6,
          w: 2,
          h: 5,
          title: "Конвертер валют",
          component: "exchange",
          i: "2",
          is_deleted: true,
          is_copy: true,
          is_edit: true,
        },
        {
          _id: 4,
          x: 0,
          y: 6,
          w: 4,
          h: 5,
          title: "Погода",
          component: "weather",
          i: "4",
          is_deleted: true,
          is_copy: true,
          is_edit: true,
        },
        {
          _id: 5,
          x: 4,
          y: 6,
          w: 2,
          h: 3,
          title: "3",
          i: "5",
          is_deleted: true,
          is_copy: true,
          is_edit: true,
        },
      ],
      draggable: true,
      resizable: true,
      index: 0,
      eventLog: [],
    };
  },
  watch: {
    eventLog: function () {
      const eventsDiv = this.$refs.eventsDiv;
      eventsDiv.scrollTop = eventsDiv.scrollHeight;
    },
  },
  mounted() {
    this.index = this.layout.length;
  },
  methods: {
    async eventCard(item) {
      console.log(item);
      switch (item.event) {
        case "removeCard": {
          const res = await this.$dialog.confirm({
            title: "Удалить",
            text: item.data.title + " ?",
            persistent: false,
          });
          if (res) {
            this.layout = this.layout.filter((o) => o._id !== item.data._id);
            //  console.log(item, this.layout);
          }
          break;
        }

        case "copy": {
          const title = await this.$dialog.prompt({
            text: "",
            value: item.data.title,
            title: "Новое название",
          });
          if (title) {
            //  let rand = Math.floor(Math.random() * Math.floor(1000));
            this.layout.push({
              ...item.data,
              title,
              _id: this.index,
              i: this.index,
            });
            this.index++;
          }
          break;
        }
      }
    },
    // moveEvent: function (i, newX, newY) {
    //   const msg = "MOVE i=" + i + ", X=" + newX + ", Y=" + newY;
    //   this.eventLog.push(msg);
    //   console.log(msg);
    // },
    // movedEvent: function (i, newX, newY) {
    //   const msg = "MOVED i=" + i + ", X=" + newX + ", Y=" + newY;
    //   this.eventLog.push(msg);
    //   console.log(msg);
    // },
    // resizeEvent: function (i, newH, newW, newHPx, newWPx) {
    //   const msg =
    //     "RESIZE i=" +
    //     i +
    //     ", H=" +
    //     newH +
    //     ", W=" +
    //     newW +
    //     ", H(px)=" +
    //     newHPx +
    //     ", W(px)=" +
    //     newWPx;
    //   this.eventLog.push(msg);
    //   console.log(msg);
    // },
    // resizedEvent: function (i, newX, newY, newHPx, newWPx) {
    //   const msg =
    //     "RESIZED i=" +
    //     i +
    //     ", X=" +
    //     newX +
    //     ", Y=" +
    //     newY +
    //     ", H(px)=" +
    //     newHPx +
    //     ", W(px)=" +
    //     newWPx;
    //   this.eventLog.push(msg);
    //   console.log(msg);
    // },
    // containerResizedEvent: function (i, newH, newW, newHPx, newWPx) {
    //   const msg =
    //     "CONTAINER RESIZED i=" +
    //     i +
    //     ", H=" +
    //     newH +
    //     ", W=" +
    //     newW +
    //     ", H(px)=" +
    //     newHPx +
    //     ", W(px)=" +
    //     newWPx;
    //   this.eventLog.push(msg);
    //   console.log(msg);
    // },
    // layoutCreatedEvent: function (newLayout) {
    //   this.eventLog.push("Created layout");
    //   console.log("Created layout: ", newLayout);
    // },
    // layoutBeforeMountEvent: function (newLayout) {
    //   this.eventLog.push("beforeMount layout");
    //   console.log("beforeMount layout: ", newLayout);
    // },
    // layoutMountedEvent: function (newLayout) {
    //   this.eventLog.push("Mounted layout");
    //   console.log("Mounted layout: ", newLayout);
    // },
    // layoutReadyEvent: function (newLayout) {
    //   this.eventLog.push("Ready layout");
    //   console.log("Ready layout: ", newLayout);
    // },
    // layoutUpdatedEvent: function (newLayout) {
    //   this.eventLog.push("Updated layout");
    //   console.log("Updated layout: ", newLayout);
    // },
  },
};
</script>

<style lang="scss">
.wrap-grid {
  width: 100%;
}
.start-card {
  height: 100%;
}
</style>
