import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
import errors from './modules/errors'
import auth from '../modules/auth/store/index'
import widgets from '../modules/widgets/store/index'


const modules = {
  errors,
  ...auth,
  ...widgets
};

export default new Vuex.Store({
  modules,
  strict: true,
  plugins: [createPersistedState({
    key: 'startStore',
    paths: []

  })]
})
